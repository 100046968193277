import { Avatar as AndAvatar, Tooltip } from "antd";
import { replaceOssImage } from "@chat/shared";
import { useUserInfo } from "@/hooks/useUserInfo.ts";
import { memo } from "react";

type AdminAvatarProps = {
    userID: string;
    size?: number;
    url?: string
}

function Avatar(props: AdminAvatarProps) {
    const { userID, size } = props;
    const { userInfo } = useUserInfo(userID);
    return <Tooltip title={ userInfo?.userName }>
        <AndAvatar src={ replaceOssImage(userInfo?.userAvatarUrl) }
                   size={ size || 28 }
                   style={ { backgroundColor: userInfo?.userAvatarUrl ? "transparent" : "rgb(0, 133, 255)", cursor: "pointer" } }
        >
            { userInfo?.userName.substring(0, 1) }
        </AndAvatar>
    </Tooltip>;
}

export default memo(Avatar);
