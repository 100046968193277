import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isShowTime } from "@chat/shared";
import { UserInfo } from "@/store/slice/common.ts";
import {
    getZimConversationList,
    ZIMConversation,
    queryConversation,
    getMessageList,
    ChatMessageItem,
    type ZIMMessage,
    MessageReceiptStatus,
    ZIMFriendInfo,
    createTimeMessage,
    MessageType,
    MessageTypeValue,
    ConversationType
} from "@chat/zim-sdk";
import { getRoomList } from "@/api/room.ts";
import store2 from "store2";
import _ from "lodash";
import { getIMProfile } from "@/utils";
import { ImUserInfo } from "@/api/im.ts";

type GifItem = {
    imgUrl: string;
    tag: string;
}
export type ChatPerson = ImUserInfo & UserInfo & Partial<ZIMFriendInfo>
export type CustomConversationItem = {
    isPassword: boolean;
    isNotInGroup: boolean;
    groupType?: 1 | 2 | 3;
} & ZIMConversation;

const initialState = {
    conversationList: [] as CustomConversationItem[],
    conversationLoading: false,
    messageList: [] as ChatMessageItem[],
    messageLoading: false,
    currentConversation: {} as ZIMConversation,
    quoteMessage: null as ChatMessageItem | null,
    currentVoice: "",
    inputText: "",
    gifList: [] as GifItem[],
    gifSetting: {
        nextPage: 1,
        finish: false
    },
    toolVisible: false,
    currentPerson: null as ChatPerson | null, // 当前正在聊的人
    currentSettingLoading: false,
    chatSettingVisible: true,

};
// 排序
const getSortList = (list: CustomConversationItem[]) => {
    const newList = [...list];
    newList.sort((a, b) => {
        if (a.lastMessage && !b.lastMessage) {
            return -1;
        }
        if (!a.lastMessage && b.lastMessage) {
            return 1;
        }
        return (b.lastMessage?.timestamp || 0) - (a.lastMessage?.timestamp || 0);
    });
    // 频道群优先顶置
    const channelList: CustomConversationItem[] = [];
    // 客服第二顶置
    const customList: CustomConversationItem[] = [];
    const normalList: CustomConversationItem[] = [];
    newList.forEach(item => {
        if (item.type === ConversationType.Peer) {
            customList.push(item);
        }
        if (item.type === ConversationType.Room) {
            if (item.groupType === 3) {
                channelList.push(item);
            } else {
                normalList.push(item);
            }
        }
    });
    return [
        ...channelList,
        ...normalList,
        ...customList,
    ];
};
const addMergeState = (cur: any, list: any[]) => {
    const len = list.length;
    const message = {
        ...cur,
        isMergeMessage: false
    };
    if (len) {
        const last = list[len - 1];
        const diff = Math.abs((last.sendTime - cur.sendTime) / 1000);
        if (last.contentType === "localTime") {
            return message;
        }
        if (diff < 20 * 60 && last.sendID === cur.sendID) {
            message.isMergeMessage = true;
        }
    }
    return message;
};
// 获取当前会话的详情
export const getConversationDetail = createAsyncThunk(
    "message/conversationDetail",
    async (options: { conversationID: string, conversationType: number }, { dispatch }) => {
        try {
            const res = await queryConversation(options.conversationID, options.conversationType);
            const { conversation } = res;
            dispatch(setCurrentConversation(conversation));
        } catch (err) {
            console.error(err);
        }
    }
);
// 异步action。 获取历史会话列表
export const getConversationList = createAsyncThunk(
    "message/conversationList",
    async () => {
        // const state = getState() as any;
        let imList: CustomConversationItem[] = [];

        // 提取im的列表
        try {
            const [res, roomRes] = await Promise.all(
                [
                    getZimConversationList(),
                    getRoomList(store2.get("userID"))
                ]
            );
            let roomList = roomRes.data.map(item => {
                const { lastMsg = {} } = item;
                let mediaMessage: Record<string, any> = {};
                if ([MessageType.Video, MessageType.Audio].includes(+lastMsg.msg_type as MessageTypeValue)) {
                    // media_duration
                    mediaMessage = JSON.parse(lastMsg.msg_body);
                    lastMsg.msg_body = null;
                }
                return {
                    ...item,
                    isPassword: item.groupType === 2,
                    conversationAlias: "",
                    conversationAvatarUrl: item.groupLogo,
                    conversationID: item.groupId,
                    conversationName: item.groupName,
                    type: ConversationType.Room,
                    isNotInGroup: !item.fillPass,
                    orderKey: 1,
                    gameCodes: item.gameCodes?.split(",").filter(Boolean),
                    lastMessage: lastMsg.msg_type ? {
                        conversationID: lastMsg.conv_id,
                        messageID: lastMsg.msg_id,
                        message: lastMsg.msg_body,
                        subType: +lastMsg.sub_msg_type,
                        type: +lastMsg.msg_type || MessageType.Unknown,
                        timestamp: +lastMsg.timestamp * 1000 || 0,
                        senderUserID: lastMsg.from_user_id || "",
                        audioDuration: +mediaMessage.media_duration || 0,
                        videoDuration: +mediaMessage.media_duration || 0,
                    } : null
                };
            });
            const { role } = getIMProfile();
            roomList = roomList.filter((item: any) => {
                if (role.includes("imCustomer")) return true;
                return item.isGroupAdmin;
            });
            imList = [...roomList, ...res.conversationList,] as CustomConversationItem[];
        } catch (err) {
            console.log(err, "err");
            return Promise.reject(err);
        }
        return {
            imList: getSortList(imList)
        };

    }
);
// 异步action。 获取历史会话记录
export const fetchHistoryList = createAsyncThunk(
    "chat/fetchHistoryList",
    async (conversationID: string) => {
        const messageList = await getMessageList(conversationID);
        return {
            messageList: messageList || []
        };
    }
);
export const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {
        // 更新或者添加
        updateConversationList(state, action) {
            const list = [...state.conversationList];
            const data = JSON.parse(JSON.stringify(action.payload));
            const currentIndex = list.findIndex(item => item.conversationID === data.conversationID);
            if (~currentIndex) {
                list.splice(currentIndex, 1, data);
            } else {
                list.unshift(data);
            }
            state.conversationList = getSortList(list);
            // // 根据最后一次发送的时间进行排序
            // list.sort((a, b) => {
            //     return b.lastMessage?.timestamp! - a.lastMessage?.timestamp!;
            // });
        },
        // 单独更新会话
        updateOneConversation(state, action) {
            const list = [...state.conversationList];
            const data = action.payload;
            const currentIndex = list.findIndex(item => item.conversationID === data.conversationID);
            if (~currentIndex) {
                list[currentIndex] = {
                    ...list[currentIndex],
                    ...data
                };
            }
            state.conversationList = getSortList(list);
        },
        setCurrentConversation: (state, action) => {
            state.currentConversation = JSON.parse(JSON.stringify(action.payload));
        },
        setMessageList(state, action) {
            state.messageList = action.payload;
        },
        addHistoryMessage(state, action) {
            state.messageList.unshift(...action.payload);
        },
        addNewMessage(state, action) {
            const list = state.messageList;
            const prev = list[list.length - 1];
            const cur = action.payload as ZIMMessage;
            const addList = [];
            const message = addMergeState(cur, list);
            const showTime = isShowTime(prev ? prev.timestamp : 0, cur.timestamp);
            if (showTime) {
                const timeMessage = createTimeMessage(action.payload.sendTime);
                addList.push(timeMessage);
                message.isMergeMessage = false;
            }
            addList.push(message);
            state.messageList.push(...addList);
        },
        removeMessage(state, action) {
            console.log("remove", action);
            state.messageList = state.messageList.filter(item => item.messageID !== action.payload);
        },
        // 清除信息
        deleteMessageFormStore(state, action) {
            // deleteMessages
            const clientMsgIDs = Array.isArray(action.payload) ? action.payload : [action.payload];
            const list = clientMsgIDs.map((item: any) => item.messageID);
            _.remove(state.messageList, item => list.includes(item.messageID));
        },
        updateMessageReceipt(state, action) {
            const cur = state.messageList.find(item => item.messageID === action.payload.messageID);
            if (cur) {
                cur.receiptStatus = action.payload.status;
            }
        },
        // 更新信息，默认更新messageID相同，如果是本地走local
        updateMessage(state, action: PayloadAction<{ key: keyof ChatMessageItem; message: ChatMessageItem }>) {
            const { payload } = action;
            const { key = "messageID", message } = payload;
            const updateIndex = state.messageList.findIndex(item => item[key] === message[key]);
            // @ts-ignore
            if (~updateIndex) {
                state.messageList[updateIndex] = {
                    ...state.messageList[updateIndex],
                    ...message
                };
            }
        },
        // 更新列表全部已读
        updateAllMessageRead(state) {
            state.messageList = state.messageList.map(item => {
                return {
                    ...item,
                    isRead: true
                };
            });
        },
        updateMessageRead(state, action) {
            const list = state.messageList;
            const msgIDList = action.payload;
            msgIDList.forEach((id: string) => {
                const cur = list.find(item => item.messageID === id);
                if (cur) {
                    cur.receiptStatus = MessageReceiptStatus.Done;
                }
            });
        },
        setQuoteMessage(state, action) {
            state.quoteMessage = action.payload;
        },
        setCurrentVoice(state, action) {
            state.currentVoice = action.payload;
        },
        updateInputText(state, action) {
            state.inputText = action.payload;
        },
        toggleToolVisible(state) {
            state.toolVisible = !state.toolVisible;
        },
        setToolVisible(state, action) {
            state.toolVisible = action.payload;
        },
        setCurrentPerson(state, action) {
            state.currentPerson = action.payload;
        },
        setCurrentSettingLoading(state, action) {
            state.currentSettingLoading = action.payload;
        },
        setChatSettingVisible(state, action) {
            state.chatSettingVisible = action.payload;
        },
        setMessageLoading(state, action) {
            state.messageLoading = action.payload;
        },
        // 切换 conversation
        reset(state) {
            return {
                ...initialState,
                conversationList: state.conversationList,
                gifList: state.gifList
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getConversationList.pending, state => {
            state.conversationLoading = true;
        });
        builder.addCase(getConversationList.fulfilled, (state, action) => {
            const { imList } = action.payload;
            const list = [...imList];
            state.conversationLoading = false;
            state.conversationList = list;
        });
        builder.addCase(getConversationList.rejected, state => {
            state.conversationLoading = false;
        });

        builder.addCase(fetchHistoryList.pending, state => {
            state.messageLoading = true;
        });
        builder.addCase(fetchHistoryList.fulfilled, (state, { payload }) => {
            const { messageList } = payload;
            state.messageLoading = false;
            state.messageList = messageList;
            // setTimeout(() => {
            //     emitter.emit("CHAT_LIST_SCROLL_TO_BOTTOM", { behavior: "auto" });
            // }, 20);
        });
        builder.addCase(fetchHistoryList.rejected, (state) => {
            state.messageLoading = false;
        });
    }
});

export const {
    setCurrentConversation,
    addNewMessage,
    addHistoryMessage,
    updateMessage,
    removeMessage,
    deleteMessageFormStore,
    updateMessageReceipt,
    updateAllMessageRead,
    updateMessageRead,
    setQuoteMessage,
    setCurrentVoice,
    updateConversationList,
    updateOneConversation,
    updateInputText,
    toggleToolVisible,
    setToolVisible,
    setCurrentPerson,
    setChatSettingVisible,
    setMessageList,
    setMessageLoading,
    setCurrentSettingLoading,
    reset
} = messageSlice.actions;
export default messageSlice.reducer;
