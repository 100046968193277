import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface DataType {
    key: React.Key;
    time: string,
    issue: string,
    forecast: null | string,
    results: string,
    status: boolean
}
const initialState = {
    visible: false,
    list: [
        {
            key: "1",
            time: "2023-07-07",
            issue: "20240711",
            forecast: "123",
            results: "",
            status: true
        },
        {
            key: "2",
            time: "2023-07-07",
            issue: "20240711",
            forecast: null,
            results: "223",
            status: true
        },
        {
            key: "3",
            time: "2023-07-07",
            issue: "20240711",
            forecast: null,
            results: "546",
            status: false
        }
    ] as DataType[],
    paginate: {
        pageSize: 30,
        totalPage: 1,
        currentPage: 0,
        totalSize: 0,
    }
};

export const predictiveSlice = createSlice({
    name: "gif",
    initialState,
    reducers: {
        setList(state, action) {
            state.list = action.payload;
        },
        showPredictive(state) {
            state.visible = true;
        },
        closePredictive(state) {
            state.visible = false;
        },
        setPaginate(state, action: PayloadAction<typeof initialState.paginate>) {
            state.paginate = {
                ...state.paginate,
                ...action.payload
            };
        },
        reset() {
            return initialState;
        }
    }
});

export const {
    showPredictive,
    setList,
    closePredictive
} = predictiveSlice.actions;
export default predictiveSlice.reducer;
