import { CopyConfig } from "antd/es/typography/Base";
import { CheckOutlined } from "@ant-design/icons";

function CopyIcon() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" fill="none">
        <g clipPath="url(#clip0_1836_1653)">
            <path
                d="M4.33325 4.14395V2.60425C4.33325 2.08648 4.75299 1.66675 5.27075 1.66675H13.3958C13.9135 1.66675 14.3333 2.08648 14.3333 2.60425V10.7292C14.3333 11.247 13.9135 11.6667 13.3958 11.6667H11.8387"
                stroke="#848FA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M10.7292 4.33325H2.60425C2.08648 4.33325 1.66675 4.75299 1.66675 5.27075V13.3958C1.66675 13.9135 2.08648 14.3333 2.60425 14.3333H10.7292C11.247 14.3333 11.6667 13.9135 11.6667 13.3958V5.27075C11.6667 4.75299 11.247 4.33325 10.7292 4.33325Z"
                stroke="#848FA0" strokeWidth="1.5" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_1836_1653">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>;
};
// 排版替换props
export const copyableProps: (text: string) => CopyConfig = (text: string) => {
    return {
        text,
        icon: [<CopyIcon/>, <CheckOutlined/>]
    };
};

