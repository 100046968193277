import { removePersonAttr, savePersonAttr, sendCustomMsg } from "@/api/room";
import { useAppSelector } from "@/store";
import { CustomType, ZIMMessage, CustomMutedMessage, CustomRemoveMessage } from "@chat/zim-sdk";

export function useCustomMessage() {
    const { userInfo } = useAppSelector(state => state.common);

    // 删除消息
    async function sendDeleteFlag(deleteMessageList: ZIMMessage[], conversationID: string, isAlsoDeleteServerMessage: boolean) {
        const message = {
            conversationID,
            deleteMessageList,
            isAlsoDeleteServerMessage,
            type: CustomType.Remove
        } as CustomRemoveMessage;
        return sendCustomMsg({
            groupId: conversationID!,
            imUserId: userInfo!.userID,
            reqData: JSON.stringify(message),
            subMsgType: CustomType.Remove,
            msgIds: deleteMessageList.map(item => item.messageID)
        });
    }

    // 禁言
    async function sendMuteFlag(conversationID: string, userID: string, duration: number) {
        const muteExpiredTime = duration ? duration + Date.now() : 0;
        await savePersonAttr({ groupId: conversationID, imUserId: userID, endTime: muteExpiredTime, operateType: 1 });
        const message = {
            conversationID,
            userID,
            muteExpiredTime,
            type: CustomType.Muted
        } as CustomMutedMessage;
        return sendCustomMsg({
            groupId: conversationID!,
            imUserId: userInfo!.userID,
            reqData: JSON.stringify(message),
            subMsgType: CustomType.Muted
        });
    }

    // 拉黑
    async function sendBlockFlag(conversationID: string, userID: string) {
        await savePersonAttr({ groupId: conversationID, imUserId: userID, operateType: 2 });
        const message = {
            conversationID,
            userID,
            type: CustomType.Block
        };
        return sendCustomMsg({
            groupId: conversationID!,
            imUserId: userInfo!.userID,
            reqData: JSON.stringify(message),
            subMsgType: CustomType.Block
        });
    }

    // 取消拉黑
    async function sendUnBlockFlag(conversationID: string, userID: string, id: string) {
        await removePersonAttr({ id });
        const message = {
            conversationID,
            userID,
            type: CustomType.UnBlock
        };
        return sendCustomMsg({
            groupId: conversationID!,
            imUserId: userInfo!.userID,
            reqData: JSON.stringify(message),
            subMsgType: CustomType.UnBlock
        });
    }

    return {
        sendDeleteFlag,
        sendMuteFlag,
        sendBlockFlag,
        sendUnBlockFlag
    };
}
