import { createSlice } from "@reduxjs/toolkit";
import { ZIMVideoMessage } from "@chat/zim-sdk";

const initialState = {
    videoModal: {
        visible: false,
        videoElem: {} as ZIMVideoMessage
    }
};
export const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        showVideoModal(state, action) {
            state.videoModal = {
                visible: true,
                videoElem: action.payload
            };
        },
        hiddenVideoModal(state) {
            state.videoModal = {
                ...state.videoModal,
                visible: false,
            };
        }
    }
});

export const { showVideoModal, hiddenVideoModal } = modalSlice.actions;
export default modalSlice.reducer;
