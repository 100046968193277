import cls from "@/pages/Message/feature/Setting/setting.module.scss";
import { Avatar, Image, Space, Spin, Tooltip, Typography } from "antd";
import closeIcon from "@/assets/svg/close.svg";
// import { copyableProps } from "@/utils";
import { useAppDispatch, useAppSelector } from "@/store";
import {
    setChatSettingVisible,
    // setMessageList
} from "@/store/slice/message.ts";
import { replaceOssImage } from "@chat/shared";
// import rightOutLine from "@/assets/svg/rightOutLined.svg";
// import Switch from "@/components/Switch";
import AdminAvatar from "@/components/Avatar";
// import { GroupMemberRole } from "@chat/zim-sdk";

const { Paragraph } = Typography;
export default function Group() {
    const {
        currentSettingLoading
    } = useAppSelector(state => state.message);
    const { onlineMemberNum, onlineMembers, adminList, imGroupVo } = useAppSelector(state => state.room);
    const dispatch = useAppDispatch();
    const handleClose = () => {
        dispatch(setChatSettingVisible(false));
    };
    // 群管理员禁言
    // const muteGroup = () => {
    //     if (!currentGroup) return;
    // };
    return <div className={ cls.setting }>
        <div className={ cls.header }>
            <div className={ cls.header__text }>
                Group chat settings
            </div>
            <Image wrapperClassName={ cls.header__close } onClick={ handleClose } src={ closeIcon } preview={ false }/>
        </div>
        <Spin spinning={ currentSettingLoading }>
            <div className={ cls.userInfo }>
                <div className={ cls.item }>
                    <div className={ cls.label }>Group chat ID</div>
                    <Paragraph
                        className={ cls.value }
                        // copyable={ copyableProps(currentGroup?.groupId || "") }
                    >{ imGroupVo?.groupId }</Paragraph>
                </div>
                <div className={ cls.item }>
                    <div className={ cls.label }>Group name</div>
                    <div className={ cls.value }>{ imGroupVo?.groupName }</div>
                </div>
                <div className={ cls.item }>
                    <div className={ cls.label }>Group avatar</div>
                    <div className={ cls.value }>
                        <Avatar size={ 40 } src={ replaceOssImage(imGroupVo?.groupLogo) }/>
                    </div>
                </div>
                <div className={ cls.item }>
                    <div className={ cls.label } style={ { cursor: "pointer" } }>
                        group members({ onlineMemberNum || 0 })
                        {/*<div className={ cls.rightContent }>*/}
                        {/*    <Image className={ cls.icon } width={ 12 } height={ 12 } src={ rightOutLine }*/}
                        {/*           preview={ false }/>*/}
                        {/*</div>*/}
                    </div>
                    <div className={ cls.memberList }>
                        <Space size={ 8 } wrap style={ { marginTop: 8 } }>
                            {
                                onlineMembers.slice(0, 18).map(item => {
                                    return <Tooltip title={ item.userName } key={ item.userID }>
                                        <Avatar src={ replaceOssImage(item.userAvatarUrl) }
                                                size={ 28 }>{ item.userName.slice(0, 1) }</Avatar>
                                    </Tooltip>;
                                })
                            }
                        </Space>
                    </div>
                </div>

                <div className={ cls.item }>
                    <div className={ cls.label } style={ { cursor: "pointer" } }>
                        group admin({ adminList.length || 0 })
                    </div>
                    <div className={ cls.memberList }>
                        <Space size={ 8 } wrap style={ { marginTop: 8 } }>
                            {
                                adminList.map(item => {
                                    return <AdminAvatar userID={ item } key={ item }></AdminAvatar>;
                                })
                            }
                        </Space>
                    </div>
                </div>
                {
                    imGroupVo?.groupInfo.trim() && <div className={ cls.item }>
                        <div className={ cls.label }>Group description</div>
                        <div style={ { marginTop: 8 } }>{ imGroupVo?.groupInfo }</div>
                    </div>
                }

                {
                    imGroupVo?.groupNotice.trim() && <div className={ cls.item }>
                        <div className={ cls.label }>Group announcement</div>
                        <div style={ { marginTop: 8 } }>{ imGroupVo?.groupNotice }</div>
                    </div>
                }

                {/*全体禁言逻辑*/ }
                {/*{*/ }
                {/*    [GroupMemberRole.Admin, GroupMemberRole.Owner].includes(selfInGroupInfo?.memberRole || GroupMemberRole.Nomal) &&*/ }
                {/*    <div className={ cls.item }>*/ }
                {/*        <div className={ cls.label }>*/ }
                {/*            Mute notifications*/ }
                {/*            <div className={ cls.rightContent }>*/ }
                {/*                <Switch onClick={ muteGroup } value={ currentGroup?.status }*/ }
                {/*                        className={ cls.switch }/>*/ }
                {/*            </div>*/ }
                {/*        </div>*/ }
                {/*    </div>*/ }
                {/*}*/ }
                {/*<div className={ cls.item }>*/ }
                {/*    <Button danger block onClick={ clearHistory }>Clear chat history</Button>*/ }
                {/*</div>*/ }

            </div>
        </Spin>

    </div>;
}
