import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import "normalize.css/normalize.css";
import "@/assets/fonts/font.css";
import "@/locales";
import { setup as SDKSetup } from "@chat/zim-sdk";
import { isProd } from "@/utils";
import "@chat/component/dist/component.css"

let ZIM_APP_ID_DEV = 601884274; // 国外 开发
let ZIM_APP_ID_PROD = 859135233; // 国外 生产
// let ZIM_APP_ID = 775389328; // 国内
if (isProd()) {
    SDKSetup(ZIM_APP_ID_PROD);
} else {
    SDKSetup(ZIM_APP_ID_DEV);
}

ReactDOM.createRoot(document.getElementById('root')!).render(
    <App/>
);
