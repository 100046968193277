import { Image, Layout, Input, message, Flex } from "antd";
// import redPacketIcon from "@/assets/message/redPacket.png";
// import giftIcon from "@/assets/message/gift.png";
import flyIcon from "@/assets/message/fly.png";
import React, { ChangeEvent, KeyboardEvent, ReactElement, useRef, useState } from "react";
import useSendMessage from "@/hooks/useSendMessage";
import { useAppDispatch, useAppSelector } from "@/store";
import { updateInputText } from "@/store/slice/message.ts";
import useFileMessage from "@/hooks/useFileMessage.ts";
import { isChinese, isImageOrVideo } from "@chat/shared";
import Face from "@/pages/Message/feature/Conversation/Tool/Face.tsx";
import FileUpdate from "@/pages/Message/feature/Conversation/Tool/FileUpdate.tsx";
import Quick from "@/pages/Message/feature/Conversation/Tool/Quick.tsx";
import Support from "@/pages/Message/feature/Conversation/Tool/Support.tsx";
import { ConversationType, ConversationTypeValue, createTextMessage, getConversationType } from "@chat/zim-sdk";
import { useParams } from "react-router-dom";

const { TextArea } = Input;
const getToolList = (type: ConversationTypeValue) => {
    type BaseToolItem = {
        component: (props: any) => ReactElement;
        title: string;
        type?: string
    }
    const baseTool: BaseToolItem[] = [
        {
            component: Face,
            title: "Face",
        },
        {
            component: FileUpdate,
            title: "GIF",
        },
    ];
    const rechangeTool: BaseToolItem[] = [
        {
            component: Quick,
            title: "QuickMessage",
            type: "quickReply"
        },
        {
            component: Support,
            title: "Support",
        },
        {
            component: Quick,
            title: "UPI",
            type: "UPI",
        }
    ];
    if (type === ConversationType.Peer) {
        baseTool.push(...rechangeTool);
    }
    return baseTool;
};

export default function Footer() {
    const { sendMessage } = useSendMessage();
    const dispatch = useAppDispatch();
    const { conversationID = "" } = useParams();
    const { quoteMessage, inputText: text } = useAppSelector(
        (state) => state.message
    );
    const [disabled, setDisabled] = useState(false);
    const fileRef = useRef<HTMLInputElement>(null);
    const { createImageOrVideoMessage } = useFileMessage();
    const toolList = getToolList(getConversationType(conversationID));
    const handleKeyDown = async (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            sendMessageValue();
        }
    };
    const sendMessageValue = async () => {
        if (!text.trim()) return;
        if (isChinese(text.trim())) {
            message.warning("The use of Chinese characters is prohibited in the chat room")
            return;
        }
        try {
            setDisabled(true);
            // 发送消息
            await sendMessage({
                message: createTextMessage(text.trim()),
                conversationID: conversationID,
                originalMessage: quoteMessage
            });
        } catch (e) {
            console.error(e);
        } finally {
            setDisabled(false);
        }
    };
    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        dispatch(updateInputText(e.target.value));
    };
    const listenFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files![0];
        e.target.value = "";
        createImageOrVideoMessage(file).then((message) => {
            // 发送消息
            sendMessage({
                message,
                conversationID: conversationID
            });
        });
    };
    const handleSendFile = async (files: FileList) => {
        // 处理文件，只有图片和视频可以粘贴
        const filesList = Array.from(files).filter((item) => {
            return isImageOrVideo(item);
        });
        const messagesList = filesList.map((file) => {
            return createImageOrVideoMessage(file);
        });
        const messageResolveList = await Promise.all(messagesList);
        for (const message of messageResolveList) {
            await sendMessage({
                message,
                conversationID: conversationID
            });
        }
    };
    // 粘贴实现
    const handlePaste = async (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
        try {
            const files = e.clipboardData.files;
            if (files.length > 0) {
                e.preventDefault();
                await handleSendFile(files);
            }
        } catch (error) {
            message.error("Paste failed");
        }
    };
    // 拖拽发送
    const handleDrop = async (e: React.DragEvent<HTMLTextAreaElement>) => {
        try {
            e.preventDefault();
            const files = e.dataTransfer.files;
            if (files.length === 0) return;
            await handleSendFile(files);
        } catch (error) {
            message.error("sending failed");
        }
    };
    return (
        <Layout.Footer className="conversation-footer">
            <Flex className="tools" gap={ 20 }>
                { toolList.map((item) => {
                    return <item.component key={ item.title } type={ item.type as any }/>;
                }) }
            </Flex>
            <div className="textArea-container">
                <TextArea
                    className="textArea"
                    onDrop={ handleDrop }
                    disabled={ disabled }
                    onKeyDown={ handleKeyDown }
                    onChange={ handleChange }
                    onPaste={ handlePaste }
                    placeholder="Enter to send, Shift + Enter for new line."
                    value={ text }
                />
                <Image
                    src={ flyIcon }
                    preview={ false }
                    onClick={ sendMessageValue }
                    className="sendBtn"
                />
            </div>
            <input
                style={ { display: "none" } }
                onChange={ listenFileChange }
                ref={ fileRef }
                type="file"
                accept="image/*,video/*"
            />
        </Layout.Footer>
    );
}
