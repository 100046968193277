import store2 from "store2";

export enum StorageKey {
    USER_ID = "userID",
    CHAT_TOKEN = "chatToken",
    ORIGIN_TOKEN = "originToken",
    PREFIX_PACKAGE = "prefixPackage",
    Role = "role"
}

export type RoleValue  = "imCustomer" | "isGroupAdmin"

export const clearIMProfile = () => {
    store2.remove(StorageKey.USER_ID);
    store2.remove(StorageKey.CHAT_TOKEN);
    store2.remove(StorageKey.ORIGIN_TOKEN);
    store2.remove(StorageKey.PREFIX_PACKAGE);
    store2.remove(StorageKey.Role);
};
export const getPrefixPackage = () => {
    const userID = store2.get(StorageKey.USER_ID) || "";
    return userID.substring(0, 2);
};

export const getIMProfile = () => {
    const imUserID = store2.get(StorageKey.USER_ID);
    const imToken = store2.get(StorageKey.CHAT_TOKEN);
    const prefixPackage = store2.get(StorageKey.PREFIX_PACKAGE);
    const role: RoleValue[] = store2.get(StorageKey.Role);
    return {
        imUserID,
        imToken,
        prefixPackage,
        role
    };
};
