import { useAppSelector } from "@/store";
import { useUnmount } from "ahooks";
import { clearConversationUnreadMessageCount, ConversationType, getConversationType } from "@chat/zim-sdk";

function useMarkRead(conversationID: string) {
    const conversationList = useAppSelector(state => state.message.conversationList);
    const markRead = async () => {
        const currentConversation = conversationList.find(item => item.conversationID === conversationID);
        if (currentConversation) {
            if (getConversationType(conversationID) !== ConversationType.Peer) {
                return
            }
            try {
                await clearConversationUnreadMessageCount(conversationID);
            } catch (err) {
                console.error(err, "read");
            }
        }
    };
    // 当用户再底部，退出页面的时候，标记已读，否则不标记
    useUnmount(() => {
        markRead();
    });
    return [markRead];
}

export default useMarkRead;
