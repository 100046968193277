import TextMessage from "@/pages/Message/feature/Conversation/MessageItem/TextMessage";
import { Col, Dropdown, Image, MenuProps, message, Row } from "antd";
import Avatar from "@/components/Avatar";
import { useAppDispatch, useAppSelector } from "@/store";
import classname from "classnames";
import PictureMessage from "@/pages/Message/feature/Conversation/MessageItem/PictureMessage";
import QuoteMessage from "@/pages/Message/feature/Conversation/MessageItem/QuoteMessage";
import replyIcon from "@/assets/svg/reply.svg";
import deleteIcon from "@/assets/svg/delete.svg";
import sendErrorIcon from "@/assets/svg/send-error.svg";
import sendingIcon from "@/assets/svg/send-sending.png";
import { deleteMessageFormStore, removeMessage, setQuoteMessage } from "@/store/slice/message.ts";
import VideoMessage from "@/pages/Message/feature/Conversation/MessageItem/VideoMessage.tsx";
import VoiceMessage from "@/pages/Message/feature/Conversation/MessageItem/VoiceMessage.tsx";
import useSendMessage from "@/hooks/useSendMessage.ts";
import CardMessage from "@/pages/Message/feature/Conversation/MessageItem/cardMessage.tsx";
import {
    ChatMessageItem,
    ConversationType,
    CustomType,
    deleteMessages as deleteSDKMessage,
    deleteMessages,
    MessageStatus,
    MessageType,
    useMessageItemClass,
    ZIMAudioMessage,
    ZIMCustomMessage,
    ZIMImageMessage,
    ZIMMessage,
    ZIMVideoMessage
} from "@chat/zim-sdk";
import { useUserInfo } from "@/hooks/useUserInfo.ts";
import { useCustomMessage } from "@/hooks/useCustomMessage.ts";
import { useParams } from "react-router-dom";
import { CopyOutlined } from "@ant-design/icons";
import { copyText, copyImageToClipboard } from "@chat/shared";
import { useTranslation } from "react-i18next";

type MessageItemProps = {
    data: ChatMessageItem;
    isSelf: boolean
}

export default function MessageItem(props: MessageItemProps) {
    const { data, isSelf } = props;
    const { conversationID, conversationType = ConversationType.Unknown } = useParams();
    const dispatch = useAppDispatch();
    const { sendDeleteFlag } = useCustomMessage();
    const { userInfo } = useUserInfo(data.senderUserID);
    const { currentPerson } = useAppSelector(state => state.message);
    const { t } = useTranslation();
    const deleteMessage = async (all: boolean) => {
        try {
            if (+conversationType === ConversationType.Room) {
                // 发送清除命令
                const res = await sendDeleteFlag([data], data.conversationID!, true);
                if (res.code === 1000002) {
                    console.log("err");
                    message.error(res.msg);
                    return;
                }
            } else {
                await deleteSDKMessage([data], conversationID!, all);
            }
            dispatch(deleteMessageFormStore([data]));
            // 提示完成
            message.success("Delete Success!");
            // 关闭弹框
        } catch (err: any) {
            message.error(JSON.stringify(err));
        }
    };
    const getMenus = () => {
        const menus: MenuProps['items'] = [
            {
                label: <Row gutter={ 8 } onClick={ () => {
                    deleteMessage(true);
                } }>
                    <Col>
                        <Image preview={ false } src={ deleteIcon } width={ 16 } height={ 16 }></Image>
                    </Col>
                    <Col style={ { color: "rgba(223, 0, 12, 1)" } }>
                        Delete message
                    </Col>
                </Row>,
                key: '3',
            },
        ];
        if (+conversationType !== ConversationType.Room) {
            const item = {
                label: <Row gutter={ 8 } onClick={ () => {
                    dispatch(setQuoteMessage(data));
                } }>
                    <Col>
                        <Image preview={ false } src={ replyIcon } width={ 16 } height={ 16 }></Image>
                    </Col>
                    <Col flex={ 1 }>
                        Reply
                    </Col>
                </Row>,
                key: '2',
            };
            menus.unshift(item);
        }
        if ([MessageType.Text, MessageType.Image].includes(data.type as any)) {
            const item = {
                label: <Row gutter={ 8 } onClick={ () => {
                    // 判断是图片还是文字，如果是文字
                    if (data.type === MessageType.Image) {
                        const downLoadUrl = (data as ZIMImageMessage).largeImageDownloadUrl || (data as ZIMImageMessage).thumbnailDownloadUrl;
                        copyImageToClipboard(downLoadUrl).then((text) => {
                            message.success(text);
                        });
                    }
                    if (data.type === MessageType.Text) {
                        copyText(data.message as string, () => {
                            message.success("Text copied to clipboard");
                        });
                    }

                } }>
                    <Col>
                        <CopyOutlined width={ 16 } height={ 16 }/>
                    </Col>
                    <Col flex={ 1 }>
                        copy
                    </Col>
                </Row>,
                key: '1'
            };
            menus.unshift(item);
        }
        return menus;
    };

    const getComponent = () => {
        switch (data.type) {
            case MessageType.Text:
                if (data.repliedInfo) {
                    return <QuoteMessage data={ data }></QuoteMessage>;
                }
                return <TextMessage data={ data }/>;
            case MessageType.Image:
                return <PictureMessage data={ data as ZIMImageMessage }></PictureMessage>;
            case MessageType.Video:
                return <VideoMessage data={ data as ZIMVideoMessage }/>;
            case MessageType.Audio:
                return <VoiceMessage data={ data as ZIMAudioMessage }/>;
            case MessageType.Custom:
                const customMessage = data as ZIMCustomMessage;
                if (customMessage.subType === CustomType.Card) {
                    return <CardMessage data={ customMessage }/>;
                }
                return t("common.Notsupported");
            default:
                return data.type;
        }
    };
    const { sendMessage } = useSendMessage();
    const repeatSend = async (message: ZIMMessage) => {
        dispatch(removeMessage(message.messageID));
        await deleteMessages([message], message.conversationID, false);
        const newMessage = JSON.parse(JSON.stringify(message));
        delete newMessage.localMessageID;
        delete newMessage.messageID;
        await sendMessage({ message: newMessage, conversationID: data.conversationID });
    };
    const renderStatus = () => {
        switch (data.sentStatus) {
            case MessageStatus.Failed:
                return <div className="message-item-status"
                            onClick={ () => repeatSend(data) }>
                    <Image src={ sendErrorIcon } preview={ false } className="message-item-status__error"/>
                </div>;
            case MessageStatus.Sending:
                return <div className="message-item-status">
                    <Image className="message-item-status__sending" preview={ false } src={ sendingIcon }></Image>
                </div>;
            default:
                return null;
        }
    };
    const getUserName = () => {
        // 判断是否是单聊
        if (data.conversationType === ConversationType.Peer) {
            return currentPerson?.friendAlias || currentPerson?.userName || userInfo?.userName;
        }
        return userInfo?.userName;
    };
    const [layoutClassname] = useMessageItemClass(props.data);
    return <div className={ classname("message-layout", isSelf && "message-layout__self") } data-key={ data.messageID }>
        <div className="message-item-icon">
            <Avatar size={ 28 } userID={ data.senderUserID }></Avatar>
        </div>
        <Dropdown overlayClassName="contextMenu" trigger={ ["contextMenu"] } menu={ { items: getMenus() } }>

            <div className={ classname("message-item-content", layoutClassname) }>
                {
                    !isSelf && <div className="nickname">{ getUserName() }</div>
                }
                {
                    getComponent()
                }
            </div>
        </Dropdown>
        {
            renderStatus()
        }
    </div>;
}
