import { GetProps } from "antd";
import Icon from "@ant-design/icons";

function SaveIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
            <path
                d="M1.75 3.125C1.75 2.64175 2.14175 2.25 2.625 2.25H9.99874L12.25 4.3519V11.875C12.25 12.3583 11.8583 12.75 11.375 12.75H2.625C2.14175 12.75 1.75 12.3583 1.75 11.875V3.125Z"
                stroke="white" strokeWidth="1.16667" strokeLinejoin="round"/>
            <path
                d="M7.00307 2.25L7.00065 4.40384C7.00065 4.50298 6.87007 4.58333 6.70898 4.58333H4.37565C4.21456 4.58333 4.08398 4.50298 4.08398 4.40384V2.25H7.00307Z"
                stroke="white" strokeWidth="1.16667" strokeLinejoin="round"/>
            <path d="M2.625 2.25H9.99874" stroke="white" strokeWidth="1.16667" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M4.08398 8.0835H9.91732" stroke="white" strokeWidth="1.16667" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M4.08398 10.4165H7.00307" stroke="white" strokeWidth="1.16667" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}

type CustomIconComponentProps = GetProps<typeof Icon>;

export default function ComponentIcon(props: Partial<CustomIconComponentProps>) {
    return <Icon component={ SaveIcon } { ...props }></Icon>;
}
