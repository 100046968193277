import React from "react";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}


function VoiceIcon(props: IconProps) {
    return <span { ...props } role="img">
<svg viewBox="0 0 16 14" fill="none">
    <path
        d="M0.74292 1.91981C0.74292 1.519 1.06783 1.19409 1.46863 1.19409H11.6286C12.0294 1.19409 12.3543 1.519 12.3543 1.91981V5.18552L15.2572 3.00838V11.3541L12.3543 9.17695V12.0798C12.3543 12.4806 12.0294 12.8055 11.6286 12.8055H1.46863C1.06783 12.8055 0.74292 12.4806 0.74292 12.0798V1.91981Z"
        fill="#848FA0" stroke="#333333" strokeWidth="1.45143" strokeLinecap="round" strokeLinejoin="round"/>
    <path
        d="M0.74292 1.91981C0.74292 1.519 1.06783 1.19409 1.46863 1.19409H11.6286C12.0294 1.19409 12.3543 1.519 12.3543 1.91981V5.18552L15.2572 3.00838V11.3541L12.3543 9.17695V12.0798C12.3543 12.4806 12.0294 12.8055 11.6286 12.8055H1.46863C1.06783 12.8055 0.74292 12.4806 0.74292 12.0798V1.91981Z"
        stroke="#848FA0" strokeWidth="1.45143" strokeLinecap="round" strokeLinejoin="round"/>
    <path
        d="M4.17139 7.05141V5.12958C4.17139 4.41147 4.96128 3.97367 5.57024 4.35427L7.02853 5.26569L8.64517 6.2761C9.21813 6.63419 9.21813 7.46863 8.64517 7.82672L7.02853 8.83712L5.57024 9.74855C4.96129 10.1292 4.17139 9.69135 4.17139 8.97324V7.05141Z"
        fill="white"/>
</svg>
    </span>;
}

export default VoiceIcon;
