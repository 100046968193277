import { Avatar, Badge, Empty, Flex, Input, List, message, Modal, Skeleton, Typography } from "antd";
import { formatConversionTime, replaceOssImage } from "@chat/shared";
import { useAppDispatch, useAppSelector } from "@/store";
import { useNavigate, useParams } from "react-router-dom";
import classnames from "classnames";
import {
    addConversationListener,
    type ConversationListener,
    createConversation,
    getConversationType,
    getUserInfo,
    type IUserInfo,
    ConversationType,
    MessageReceiptStatus,
    queryConversation,
    removeConversationListener,
    ZIMConversation
} from "@chat/zim-sdk";
import LastMessage from "@/pages/Message/feature/Sider/LastMessage";
import { useEffect, useState } from "react";
import { setCurrentConversation, updateConversationList } from "@/store/slice/message";
import { NewChat, ReadStatus } from "@/components/icon";
import { useLatest } from "ahooks";
import { getIMProfile, getPrefixPackage } from "@/utils";

const { Paragraph } = Typography;
export default function Sider() {
    const [visible, setVisible] = useState(false);
    const [searchUserId, setSearchUserId] = useState("");
    const [searchUser, setSearchUser] = useState<IUserInfo | null>(null);

    const params = useParams();
    const navigate = useNavigate();
    const { conversationID } = params;
    const { conversationLoading, conversationList, currentConversation } = useAppSelector(state => state.message);
    const { isLogining } = useAppSelector(state => state.common);

    const latestcurrentConversation = useLatest(currentConversation);
    const handleClick = (values: ZIMConversation) => {
        navigate(`/chat/message/${ values.conversationID }/${ values.type }`, { replace: true });
    };
    const dispatch = useAppDispatch();
    const listener = () => {
        const onConversationChanged: ConversationListener["onConversationChanged"] = (infoList) => {
            const currentConversation = latestcurrentConversation.current;
            infoList.forEach(item => {
                if (item.conversation.type === ConversationType.Peer) {
                    dispatch(updateConversationList(item.conversation));
                    if (currentConversation.conversationID === item.conversation.conversationID) {
                        dispatch(setCurrentConversation(item.conversation));
                    }
                }
            });
        };
        const onTotalUnreadMessageCountChanged: ConversationListener["onTotalUnreadMessageCountChanged"] = (totalUnreadMessageCount: number) => {
            console.log(totalUnreadMessageCount, "--->unread");
        };
        addConversationListener({
            onConversationChanged,
            onTotalUnreadMessageCountChanged
        });
        return () => removeConversationListener();
    };
    const handleNewChat = () => {
        if (searchUser) {
            const { imUserID } = getIMProfile();
            if (searchUser.userID === imUserID) {
                message.warning("Cannot chat with oneself!");
                return;
            }
            // 先判断是否存在会话，如果不存在，那么创建一个
            navigate(`/chat/message/${ searchUser.userID }/${ ConversationType.Peer }`, { replace: true });
            queryConversation(searchUser.userID).then(() => {
                // 存在会话
            }).catch(err => {
                if (err.code === 6000603) {
                    // 不存在会话，需要创建
                    // 不存在
                    const conversation = createConversation({
                        conversationID: searchUser.userID,
                        conversationName: searchUser.userName,
                        type: ConversationType.Peer,
                        conversationAvatarUrl: searchUser.userAvatarUrl
                    });
                    dispatch(updateConversationList(conversation));
                    dispatch(setCurrentConversation(conversation));
                }
            });
            setVisible(false);
        }
    };
    const handleSearch = async (value: string) => {
        try {
            const prefix = getPrefixPackage();
            const packageUserId = prefix + value;
            const res = await getUserInfo(packageUserId, true);
            setSearchUser(res.user);
        } catch (err) {
            setSearchUser(null);
            message.warning("User does not exist");
        }
    };
    const openModal = () => {
        setSearchUser(null);
        setSearchUserId("");
        setVisible(true);
    };
    // 在这里进行监听会话变更
    useEffect(() => {
        if (isLogining) {
            return;
        }
        const removeListener = listener();
        return () => {
            removeListener();
        };
    }, []);
    return <div className="sider">
        <div className="newChat">
            <div className="newChat-box" onClick={ openModal }>
                <NewChat width={ 16 } height={ 16 }></NewChat>
                <span>New Chat</span>
            </div>
        </div>
        <List itemLayout="horizontal" rootClassName="conversation-list" loading={ conversationLoading }
              dataSource={ conversationList }
              renderItem={ (item) => {
                  const classname = classnames({
                      "conversation-item": true,
                      "conversation-item__checked": conversationID === item.conversationID
                  });
                  return <List.Item className={ classname } onClick={ handleClick.bind(null, item) }>
                      <Skeleton loading={ conversationLoading } avatar active>
                          <List.Item.Meta
                              avatar={ <Badge size="small" count={ item.unreadMessageCount }>
                                  <Avatar
                                      style={ { backgroundColor: item.conversationAvatarUrl ? "transparent" : "rgb(0, 133, 255)" } }
                                      size={ 48 }
                                      src={ replaceOssImage(item.conversationAvatarUrl) }>{ item.conversationName.slice(0, 1) }</Avatar>
                              </Badge> }
                              title={ <Flex justify="space-between" className="title" align="center">
                                  <div
                                      className="showName">{ item.conversationAlias || item.conversationName }</div>
                                  <div
                                      className="time">{ formatConversionTime(item.lastMessage?.timestamp!, true) }</div>
                              </Flex> }
                              description={ item.lastMessage &&
                                  <Flex align="center" gap={ 2 }>
                                      <Paragraph ellipsis={ { rows: 1 } } className="item-text">
                                          <LastMessage message={ item.lastMessage }></LastMessage>
                                      </Paragraph>
                                      {
                                          +getConversationType(item.conversationID) === ConversationType.Peer &&
                                          <ReadStatus
                                              read={ item.lastMessage.receiptStatus === MessageReceiptStatus.Done }
                                              style={ {
                                                  display: "inline-block",
                                                  width: 16,
                                                  height: 16,
                                                  flexShrink: 0
                                              } }/>
                                      }
                                  </Flex>
                              }
                          ></List.Item.Meta>
                      </Skeleton>
                  </List.Item>;
              } }></List>
        <Modal width={ 360 }
               open={ visible }
               title="New Chat"
               okText="Start Chat"
               okButtonProps={ {
                   disabled: !searchUser
               } }
               onCancel={ () => setVisible(false) }
               onOk={ handleNewChat }>
            <Input.Search value={ searchUserId }
                          onChange={ val => setSearchUserId(val.target.value) }
                          onSearch={ handleSearch }
                          placeholder="Please enter the User ID"></Input.Search>
            {
                searchUser ?
                    <Flex style={ { borderRadius: 4, background: "#f5f5f5", padding: "12px 0", marginTop: 12 } }
                          align="center" justify="center" gap={ 8 }>
                        <Avatar size={ 36 } src={ searchUser?.userAvatarUrl }/>
                        <div style={ { fontWeight: 500, color: "#191c1f" } }>
                            <div style={ { fontSize: 14 } }>ID: { searchUser?.userID }</div>
                            <div style={ { fontSize: 12, color: "#848fa0" } }>Username: { searchUser?.userName }</div>
                        </div>
                    </Flex> : <Empty style={ { marginTop: 12 } }/>
            }

        </Modal>
    </div>;
}
