import { Flex, Form, FormInstance, Input, InputRef, Table, TableProps } from "antd";
import { DiceType, Reflex } from "@chat/component";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { DataType, setList } from "@/store/slice/predictive.ts";
import { useAppDispatch, useAppSelector } from "@/store";

const EditableContext = createContext<FormInstance<any> | null>(null);
type ColumnTypes = Exclude<TableProps<any>['columns'], undefined>;

function EditableRow({ index, ...props }: any) {
    const [form] = Form.useForm();
    return <Form form={ form } component={ false }>
        <EditableContext.Provider value={ form }>
            <tr { ...props }></tr>
        </EditableContext.Provider>
    </Form>;
}

const EditableCell = ({
                          title,
                          editable,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          ...restProps
                      }: any) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;
    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        const value = record[dataIndex];
        form.setFieldsValue({
            [dataIndex]: value,
        });
    };
    const save = async () => {
        try {
            const formValues = await form.validateFields();
            setEditing(!editing);
            // toggleEdit();
            handleSave({
                ...record,
                ...formValues,
            });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={ {
                    margin: 0,
                } }
                rules={ [{ required: true, message: `${ title } is required.` }] }
                name={ dataIndex }
            >
                <Input style={ { width: "100px" } } ref={ inputRef } maxLength={ 3 } onPressEnter={ save }
                       onBlur={ save }/>
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={ {
                    paddingInlineEnd: 24,
                } }
                onClick={ toggleEdit }
            >
                { children }
            </div>
        );
    }
    return <td { ...restProps }>{ childNode }</td>;
};

function PredictiveTable() {
    const dispatch = useAppDispatch();
    const { list } = useAppSelector(state => state.predictive);
    const columns: (ColumnTypes[number] & { editable?: boolean }) [] = [
        {
            title: "Time",
            dataIndex: "time",
            editable: true,
            key: "time",

        },
        {
            title: "ISSUE",
            dataIndex: "issue",
            key: "issue"
        },
        {
            title: "FORECAST RESULTS",
            dataIndex: "forecast",
            key: "forecast",
            onCell: record => ({
                record,
                editable: true,
                dataIndex: "forecast",
                title: "forecast",
                handleSave,
            }),
            render(val: string) {
                if (!val) {
                    return "-";
                }
                const arr = [...val.trim()];
                return <Flex>
                    {
                        arr.map((item, index) => {
                            return <Reflex.Dice key={ index } type={ DiceType.SIGNLE } value={ item }/>;
                        })
                    }
                </Flex>;
            }

        },
        {
            title: "WINNING RESULTS",
            dataIndex: "results",
            key: "results",
            render(val: string) {
                if (!val) {
                    return "-";
                }
                const arr = [...val.trim()];
                return <Flex>
                    {
                        arr.map((item, index) => {
                            return <Reflex.Dice key={ index } type={ DiceType.SIGNLE } value={ item }/>;
                        })
                    }
                </Flex>;
            }
        },
        {
            title: "STATUS",
            dataIndex: "status",
            key: "status",
            render(val) {
                const color = val ? "#0085FF" : "#8696A5";
                const text = val ? "HIT" : "Missed";
                return <span style={ { color, fontWeight: 500 } }> { text }</span>;
            }
        },
    ];
    const handleSave = (row: DataType) => {
        const newData = [...list];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        dispatch(setList(newData));
    };
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell
        },
    };
    return <Table
        style={ { marginTop: 12 } }
        columns={ columns }
        dataSource={ list }
        size="small"
        components={ components }
        rowClassName={ (_record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark' }
        pagination={ {
            total: 200,
            pageSize: 20,
            showTotal: (total) => `Total ${ total } items`,
            showSizeChanger: false
        } }
    ></Table>;
}

export default PredictiveTable;
