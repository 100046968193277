import { Button, Empty, Flex, Image, message, Popover, Space } from "antd";
import { SupportIcon } from "@/components/icon";
import CloseIcon from "@/assets/svg/close.svg";
import Avatar from "@/assets/svg/avatar.svg";
import cls from "./tool.module.scss";
import { useEffect, useMemo, useState } from "react";
import { CUSTOM_TYPE, getCustomer, IdentityItem } from "@/api/im";
import useSendMessage from "@/hooks/useSendMessage.ts";
import { useRequest } from "ahooks";
import { SyncOutlined } from "@ant-design/icons";
import { getIMProfile, UserStatusValue } from "@/utils";
import { useParams } from "react-router-dom";
import { createServiceCardMessage } from "@chat/zim-sdk";

const LINE_STATUS: Map<UserStatusValue, string> = new Map([
    [UserStatusValue.ON_LINE, "online"],
    [UserStatusValue.BUSY, "busy"],
    [UserStatusValue.AWAY, "away"],
    [UserStatusValue.OFF_LINE, "offline"],
]);

const supportList = [
    {
        label: "Game Support",
        key: CUSTOM_TYPE.GAME
    },
    {
        label: "Recharge Support",
        key: CUSTOM_TYPE.RECHARGE
    },
];

function Support() {
    const [list, setList] = useState<IdentityItem[]>([]);
    const [customerType, setCustomerType] = useState(CUSTOM_TYPE.RECHARGE);
    const { conversationID } = useParams();
    const [open, setOpen] = useState(false);
    const { sendMessage } = useSendMessage();
    const { imUserID } = getIMProfile();
    const { loading, run } = useRequest(getCustomer, {
        onSuccess(res) {
            if (res.code === 0) {
                setList(res.data);
            }
        },
        onError() {
        },
        onFinally() {

        }
    });

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
        if (newOpen) {
            // 更新数据
        }
    };
    // 发送卡片信息
    const sendCustomer = async (item: IdentityItem) => {
        if (!conversationID) return;
        try {
            const cardMessage = await createServiceCardMessage({
                userID: item.imUserId,
                faceURL: item.userAvatar,
                nickname: item.userName
            });
            await sendMessage({
                message: cardMessage,
                conversationID,
            });
            setOpen(false);
        } catch (e) {
            message.error(`Send failed.${ e }`);
        }
    };
    const showList = useMemo(() => {
        return list.filter((v) => v.imCustomer === customerType && v.imUserId !== imUserID);
    }, [list, customerType]);
    useEffect(() => {
        run();
    }, []);
    return (
        <Popover
            open={ open }
            onOpenChange={ handleOpenChange }
            // trigger="click"
            content={
                <div className={ cls.support_container }>
                    <Flex
                        justify="space-between"
                        className={ cls.quickMessage__title }
                        style={ { marginBottom: 7 } }
                    >
                        <div>Recommend customer service</div>
                        <Image
                            className={ cls.support_close }
                            width={ 24 }
                            height={ 24 }
                            src={ CloseIcon }
                            preview={ false }
                            onClick={ () => setOpen(false) }
                        ></Image>
                    </Flex>
                    <Space size={ 12 } className={ cls.support_btns }>
                        { supportList.map((v) => (
                            <Button
                                key={ v.key }
                                style={ {
                                    background: v.key === customerType ? "#0085ff" : "#fff",
                                    color: v.key === customerType ? "#fff" : "#363B43",
                                    borderRadius: v.key === customerType ? "4px" : "0px",
                                    border:
                                        v.key === customerType
                                            ? "1px solod rgab(0,0,0,0)"
                                            : "1px solid #CED4E3",
                                } }
                                onClick={ () => {
                                    setCustomerType(v.key);
                                } }
                            >
                                { v.label }
                            </Button>
                        )) }
                        <Button size="small" key="sync" onClick={ run }
                                icon={ <SyncOutlined spin={ loading }/> }>Sync</Button>

                    </Space>
                    <ul className={ cls.support_list } style={ { maxHeight: 512 } }>
                        {
                            !loading && showList.length === 0 ?
                                <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE }/> : showList
                                    .map((item) => {
                                        return (
                                            <li key={ item.userId } className={ cls.support_item }>
                                                <Image
                                                    className={ cls.support_item__avatar }
                                                    width={ 40 }
                                                    height={ 40 }
                                                    src={ item.userAvatar || Avatar }
                                                    preview={ false }
                                                ></Image>
                                                <div className={ cls.support_item__info }>
                                                    <p className={ cls.support_info__name }>{ `${ customerType === CUSTOM_TYPE.RECHARGE ? "Recharges" : "Games" } ${ item.userName }` }</p>
                                                    <p className={ cls[`support_info__${ LINE_STATUS.get(item.imUserStatus) }`] }>
                                                        { LINE_STATUS.get(item.imUserStatus) }
                                                    </p>
                                                </div>
                                                <Button disabled={ item.imUserStatus !== UserStatusValue.ON_LINE }
                                                        onClick={ () => sendCustomer(item) }>
                                                    Send
                                                </Button>
                                            </li>
                                        );
                                    })
                        }

                    </ul>
                </div>
            }
        >
            <SupportIcon
                className="tools-item"
            ></SupportIcon>
        </Popover>
    );
}

export default Support;
