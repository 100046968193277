import { useCallback } from "react";
import {
    addNewMessage,
    ChatPerson,
    deleteMessageFormStore,
    fetchHistoryList,
    getConversationDetail,
    reset,
    setCurrentPerson,
    setMessageList,
    setMessageLoading,
    updateMessageReceipt
} from "@/store/slice/message";
import {
    setAdminList,
    setImGroupVo,
    setSelfInGroupInfo,
} from "@/store/slice/room";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "@/store";
import {
    ConversationType,
    enterConversation as SDKEnterConversation,
    leaveConversation as SDKLeaveConversation,
    enterRoom as enterSDKRoom,
    leaveRoom,
    getUserInfo,
    queryFriendsInfo,
    checkFriendsRelation,
    getConversationType,
    decorationMessage,
    ZIMCustomMessage,
    getRoomMemberCount,
    getRoomMemberList,
    RoomEvent,
    getGroupUserInfo,
    normalizeHistoryMessage
    // RoomEvent,
} from "@chat/zim-sdk";
import { emitter, StorageKey } from "@/utils";
// import { resetGif } from "@/store/slice/git";
import { getAdminList, getUserGroupInfo } from "@/api/room.ts";
import store2 from "store2";
import { useUpdateRoomLastMsg } from "@/hooks/useUpdateRoomLastMsg.ts";
import { setOnlineMemberNum, setOnlineMembers, updateOnlineMember } from "@/store/slice/room.ts";
import { getImUserInfo } from "@/api/im.ts";

const getMemberList = async (conversationID: string) => {
    try {
        const res = await getAdminList({ groupId: conversationID });
        const adminList: string[] = res.data.map((item: any) => item.imUserId);
        return adminList;
    } catch (err) {
        console.log(err);
    }
};
// 初始化当前聊天的信息，如果是群提取群信息，如果是个人提取个人
export default function useChatInit() {
    const params = useParams();
    const { conversationID, conversationType } = params;
    const dispatch = useAppDispatch();
    const { updateRoomLastMsg } = useUpdateRoomLastMsg();
    // 单聊的信息
    const getPersonalDetail = async (userID: string) => {
        const [userInfo, isfriend, friendInfos, imUserInfo] = await Promise.all([
            getUserInfo(userID, true),
            checkFriendsRelation(userID),
            queryFriendsInfo(userID),
            getImUserInfo(userID)
        ]);
        // 获取平台的用户信息
        const user = userInfo.user;
        const friendInfo = friendInfos.friendInfos[0] || {};
        let result = {
            ...user,
            ...imUserInfo.data
        } as ChatPerson;
        // 如果是好友还需要新增备注
        if (isfriend) {
            result = {
                ...result,
                ...user,
                ...friendInfo
            };
        }
        dispatch(setCurrentPerson(result));
    };
    const chatInit = useCallback(() => {
        if (!conversationID) return;
        const leaveConversation = enterConversation(conversationID, store2.get(StorageKey.USER_ID));
        return () => {
            dispatch(reset());
            leaveConversation?.();
            console.log("leave conversation ---->", Date.now());
            // dispatch(resetGif());
        };
    }, [conversationID, conversationType]);

    const enterConversation = (conversationID: string, userID: string) => {
        console.log("before enter conversation ---->", Date.now());
        const conversationType = getConversationType(conversationID);
        // 个人
        if (conversationType === ConversationType.Peer) {
            enterPeerOrGroupRoom(conversationID);
            return () => SDKLeaveConversation(conversationID);
        }
        // 群组
        if (conversationType === ConversationType.Room) {
            dispatch(setMessageLoading(true));
            // 获取房间信息
            getUserGroupInfo({ groupId: conversationID, imUserId: store2.get(StorageKey.USER_ID) }).then(res => {
                const { data, code } = res;
                if (code === 0) {
                    if (!data.imGroupVo.groupId) {
                        // 群被解散
                        // dispatch(showDismissVisible());
                        return;
                    }
                    // 获取用户在群里的信息
                    const user = getGroupUserInfo(data, store2.get(StorageKey.USER_ID));
                    // todo 公开房或者已经输入密码，那么加入房间
                    const canJoin = () => {
                        if (user.block) {
                            // toast("You have been blocked", "error");
                            // navigate("/home", { replace: true });
                            return false;
                        }
                        // 频道或者非密码房
                        if ([1, 3].includes(data.imGroupVo.groupType) || data.fillPass) {
                            return true;
                        }
                        return false;
                    };
                    if (canJoin()) {
                        const { groupId, groupName } = data.imGroupVo;
                        // 加入房间
                        enterRoom({ roomName: groupName, roomID: groupId }, userID).then(res => {
                            console.log("enterRoom success！", res);
                            // 获取群成员数量
                            getRoomMemberCount(groupId).then(res => {
                                dispatch(setOnlineMemberNum(res.count));
                            });
                            // 获取群成员
                            getRoomMemberList(groupId, { count: 100, nextFlag: "" }).then(res => {
                                dispatch(setOnlineMembers(res.memberList));
                            });
                        });
                        // 获取管理员列表
                        getMemberList(conversationID).then(res => {
                            dispatch(setAdminList(res || []));
                        });
                        // 获取用户数据
                        dispatch(setSelfInGroupInfo(user));
                        // 更新群信息，
                        dispatch(setImGroupVo(data.imGroupVo));
                        // 更新历史消息
                        if (data.lastMsgList) {
                            const historyList = data.lastMsgList.map(normalizeHistoryMessage);
                            const decorationList = decorationMessage(historyList as ZIMCustomMessage[], conversationID);
                            dispatch(setMessageList(decorationList));

                            dispatch(setMessageLoading(false));
                            setTimeout(() => {
                                emitter.emit("CHAT_LIST_SCROLL_TO_BOTTOM", {
                                    behavior: "auto",
                                    index: decorationList.length - 1
                                });
                            }, 10);
                        }

                    } else {
                        dispatch(setMessageLoading(false));
                    }
                } else {
                    dispatch(setMessageLoading(false));
                    // toast(res.msg || res.message, "error");
                }
            });
            return () => leaveRoom(conversationID);
        }
    };
    const enterPeerOrGroupRoom = (conversationID: string) => {
        SDKEnterConversation(conversationID, {
            onMessages: (list) => {
                // 更新信息
                list.forEach(message => {
                    dispatch(addNewMessage(message));
                });
                emitter.emit("CHAT_LIST_SCROLL_BY_SHOW_BTN");
            },
            onMessageReceiptChanged(infos) {
                infos.forEach(item => {
                    dispatch(updateMessageReceipt(item));
                });
            }
        }).then(() => {
            // 获取历史消息
            dispatch(fetchHistoryList(conversationID));
        }).finally(() => {
            getPersonalDetail(conversationID);
            // 获取会话信息
            dispatch(getConversationDetail({ conversationID, conversationType: getConversationType(conversationID) }));

        });
        // return () => {
        //     leaveConversation(conversationID);
        //     dispatch(resetGif());
        // };
    };
    const enterRoom = async (roomInfo: {
        roomID: string;
        roomName: string
    }, userID: string) => {
        return enterSDKRoom(roomInfo, userID, {
            onMessages: (list) => {
                // 更新信息
                list.forEach(message => {
                    dispatch(addNewMessage(message));
                    updateRoomLastMsg(message);
                });
                emitter.emit("CHAT_LIST_SCROLL_BY_SHOW_BTN");
                // 需要对数据处理，可能是自定义的系统消息，这个不需要添加到应用中
            },
            onRoomMemberJoined(data) {
                dispatch(updateOnlineMember({
                    type: "add",
                    data: data.memberList
                }));
            },
            onRoomMemberLeft(data) {
                dispatch(updateOnlineMember({
                    type: "sub",
                    data: data.memberList
                }));
            },
            onMutedMessage: (data) => {
                if (data.muteExpiredTime > Date.now()) {
                    // 被禁言
                    // toast("You have been muted.", "error");
                } else {
                    // 解除禁言
                    // toast("You can speak now.");
                }
                // dispatch(setSelfMuted(data.muteExpiredTime));
            },
            onMessageDelete: data => {
                console.log("message-remove", data);
                dispatch(deleteMessageFormStore(data.deleteMessageList));
            },
            onBlockMessage: () => {
                // 被拉黑
                // toast("You have been blocked by the administrator", "error");
                // navigate("/home", { replace: true });
            },
            onRoomStateChanged: data => {
                // 房间状态变更
                // 需要通过类型去判断
                switch (data.event) {
                    case RoomEvent.RoomNotExist:
                        // room不存在
                        // toast("Room does not exist", "error");
                        // dispatch(resetChat());
                        // navigate("/home", { replace: true });
                        break;
                    case RoomEvent.Success:
                        console.log("room enter success");
                        break;
                    case RoomEvent.KickedOut:
                        console.log("roomKickedOut");
                        // toast("You have been kicked out of this room.", "error");
                        // dispatch(resetChat());
                        // navigate("/home", { replace: true });
                        break;
                    case RoomEvent.KickedOutByOtherDevice:
                        console.log("roomKickedOutByOtherDevice");
                        // dispatch(resetChat());
                        // toast("Logged out due to multiple device logins", "error");
                        // navigate("/home", { replace: true });
                        break;
                }
            },
            onRoomInfoChanged() {
                // 房间信息变更
                getUserGroupInfo({ groupId: roomInfo.roomID, imUserId: userID }).then(res => {
                    if (res.code === 0) {
                        dispatch(setImGroupVo(res.data.imGroupVo));
                    }
                });
            },
            onRoomDismiss() {
                // 解散
                leaveRoom(roomInfo.roomID);
                // dispatch(showDismissVisible());
            }
        });
    };
    return {
        chatInit,
        conversationID,
        conversationType
    };
}



