import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import logger from "redux-logger";
import { commonSlice } from "@/store/slice/common";
import { messageSlice } from "@/store/slice/message";
import { modalSlice } from "@/store/slice/modal";
import { gifSlice } from "@/store/slice/git";
import { roomSlice } from "@/store/slice/room.ts";
import { predictiveSlice } from "@/store/slice/predictive.ts";

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
const store = configureStore({
    reducer: {
        common: commonSlice.reducer,
        message: messageSlice.reducer,
        model: modalSlice.reducer,
        gif: gifSlice.reducer,
        room: roomSlice.reducer,
        predictive: predictiveSlice.reducer
    },
    middleware(getDefaultMiddleware) {
        let middleware = getDefaultMiddleware();
        const customWare = [];
        if (import.meta.env.MODE !== "production") {
            customWare.push(logger);
        }
        return middleware.concat(customWare);
    }
});
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
