import { Alert, Image } from "antd";
import noticeIcon from "@/assets/svg/notice.svg";
import { useParams } from "react-router-dom";
import { useAppSelector } from "@/store";
import Marquee from "react-fast-marquee";
import { ConversationType } from "@chat/zim-sdk";
import { useEffect, useRef, useState } from "react";
import { AlertRef } from "antd/es/alert/Alert";

function Notice() {
    const params = useParams();
    const group = useAppSelector(state => state.room.imGroupVo);
    const { conversationType } = params;
    const [play, setPlay] = useState(false);
    const noticeTextRef = useRef<any>(null);
    const noticeContainer = useRef<AlertRef>(null);
    useEffect(() => {
        if (group?.groupNotice && noticeTextRef.current && noticeContainer.current) {
            const container: any = noticeContainer.current.nativeElement;
            const containerWidth = container.querySelector(".ant-alert-message").offsetWidth;
            const textWidth = noticeTextRef.current.querySelector(".rfm-initial-child-container").offsetWidth;
            setPlay(containerWidth < textWidth);
        }
    }, [group?.groupNotice, noticeTextRef.current]);
    if (!conversationType || +conversationType == ConversationType.Peer || !group) return null;
    if (!group.groupNotice.trim()) return null;
    return <Alert
        type="info"
        banner
        showIcon
        icon={ <div className="notice-icon">
            <Image src={ noticeIcon } preview={ false }/>
        </div> }
        ref={ noticeContainer }
        closable
        // closeIcon={ <CloseIcon size={ 24 } className="close-icon"/> }
        message={
            <Marquee className="notice-text"
                     autoFill={ false }
                     ref={ noticeTextRef }
                     play={ play }
                     gradient={ play }
            >group chat announcement: { group.groupNotice }</Marquee>
        }
        className="conversation-content-notice"
    />;
}

export default Notice;
