import { Image } from "antd";
import v0 from "./image/v0.webp";
import v1 from "./image/v1.webp";
import v2 from "./image/v2.webp";
import v3 from "./image/v3.webp";
import v4 from "./image/v4.webp";
import v5 from "./image/v5.webp";
import v6 from "./image/v6.webp";
import v7 from "./image/v7.webp";
import v8 from "./image/v8.webp";
import v9 from "./image/v9.webp";
import { CSSProperties } from "react";

type VipRectProps = {
    level: number,
    width?: number;
    height?: number;
    style?: CSSProperties;
}

const levelList = [
    v0,
    v1,
    v2,
    v3,
    v4,
    v5,
    v6,
    v7,
    v8,
    v9
];

function VipRect(props: VipRectProps) {
    const { level, width = 52, height = 20, style = {} } = props;
    return <Image src={ levelList[level] || v0 } width={ width } height={ height } wrapperStyle={ style }></Image>;
}

export default VipRect;
