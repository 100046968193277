import { ThemeConfig } from "antd/es/config-provider/context";

const antThemeConfig: ThemeConfig = {
    cssVar: true,
    hashed: false,
    token: {
        colorPrimary: "#0085FF",
        colorText: "#191c1f"
    },
    components: {
        Layout: {
            siderBg: "#304156"
        },
        List:{
            avatarMarginRight: 8
        },
        Modal:{
            // contentBg:"#191C1F"
        },
        Table: {
            headerBg: "#D9EEFF",
            headerColor: "#4E5358",
            headerSplitColor:"transparent"
        }
    }
};

export default antThemeConfig;
