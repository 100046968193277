import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserInfo, IUserInfo } from "@chat/zim-sdk";
import { getCustomer, setCustomer } from "@/api/im";
import { message } from "antd";
import { getIMProfile, UserStatusValue } from "@/utils";

export type UserInfo = IUserInfo & {
    onlineStatus: UserStatusValue
}
const initialState = {
    lng: "en-US",
    isLogining: false,
    userInfo: null as UserInfo | null,
};
export const getImUserInfo = createAsyncThunk("common/userInfo", async (_, { dispatch }) => {
    try {
        const { imUserID } = getIMProfile();
        // getSelfUserOnline
        const [{ user }, { data: customerList }] = await Promise.all([
            getUserInfo(imUserID, true),
            getCustomer()
        ]);
        const info = {
            ...user,
            onlineStatus: UserStatusValue.OFF_LINE
        };
        const cur = customerList.find(item => item.imUserId === imUserID);
        if (cur) {
            info.onlineStatus = cur.imUserStatus;
        }
        dispatch(setSelfUser(info));
    } catch (err) {
        console.log(err);
    }
});
export const setImUserOnline = createAsyncThunk("common/setSelfOnline", async (onlineStatus: UserStatusValue, { dispatch }) => {
    try {
        const { code } = await setCustomer({
            imUserStatus: onlineStatus
        });
        if (code === 0) {
            dispatch(setSelfOnline(onlineStatus));
        } else {
            message.error("Settings Failed！");
        }
    } catch (err) {
        message.error("Settings Failed！");
    }
});
export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        updateLogining: (state, action) => {
            state.isLogining = action.payload;
        },
        // 获取im用户信息
        setSelfUser(state, action) {
            state.userInfo = action.payload;
        },
        updateSelfUser(state, action) {
            state.userInfo = {
                ...state.userInfo,
                ...action.payload
            };
        },
        setSelfOnline(state, action) {
            if (state.userInfo) {
                state.userInfo = {
                    ...state.userInfo,
                    onlineStatus: action.payload
                };
            }

        },
    }
});

export const { updateLogining, setSelfUser, setSelfOnline, updateSelfUser } = commonSlice.actions;
export default commonSlice.reducer;
