import { Image, Space, Spin } from "antd";
import MessageState from "./MessageState";
import { CSSProperties, useState } from "react";
import { downLoadByUrl, replaceOssImage } from "@chat/shared";
import { ZIMImageMessage, MessageStatus } from "@chat/zim-sdk";
import {
    DownloadOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined, UndoOutlined, ZoomInOutlined,
    ZoomOutOutlined
} from "@ant-design/icons";

type ImageMessageProps = {
    data: ZIMImageMessage;
}
export default function PictureMessage(props: ImageMessageProps) {
    const { data } = props;
    const { thumbnailWidth, thumbnailHeight, thumbnailDownloadUrl, largeImageDownloadUrl } = data;
    let localWidth = thumbnailWidth > 200 ? 200 : thumbnailWidth;
    const scale = localWidth / thumbnailWidth;
    const localHeight = thumbnailHeight * scale;
    const style: CSSProperties = {
        width: Math.round(localWidth),
        height: Math.round(Math.min(localHeight, 400)),
        minWidth: 100
    };
    const [loaded, setLoaded] = useState(true);
    const isSending = data.sentStatus === MessageStatus.Sending;
    return <>
        <Spin spinning={ loaded || isSending }>
            <div className="image-message">
                <Image className="image-message-img"
                       src={ replaceOssImage(thumbnailDownloadUrl) }
                       preview={ {
                           src: replaceOssImage(largeImageDownloadUrl || thumbnailDownloadUrl),
                           toolbarRender(_,
                                         {
                                             transform: { scale },
                                             actions: {
                                                 onFlipY,
                                                 onFlipX,
                                                 onRotateLeft,
                                                 onRotateRight,
                                                 onZoomOut,
                                                 onZoomIn,
                                                 onReset,
                                             },
                                         },
                           ) {
                               return <Space size={ 12 } className="toolbar-wrapper">
                                   <DownloadOutlined onClick={ () => downLoadByUrl(replaceOssImage(thumbnailDownloadUrl)) }/>
                                   <SwapOutlined rotate={ 90 } onClick={ onFlipY }/>
                                   <SwapOutlined onClick={ onFlipX }/>
                                   <RotateLeftOutlined onClick={ onRotateLeft }/>
                                   <RotateRightOutlined onClick={ onRotateRight }/>
                                   <ZoomOutOutlined disabled={ scale === 1 } onClick={ onZoomOut }/>
                                   <ZoomInOutlined disabled={ scale === 50 } onClick={ onZoomIn }/>
                                   <UndoOutlined onClick={ onReset }/>
                               </Space>;
                           }
                       } }
                       style={ style }
                       onLoad={ () => setLoaded(false) }
                       placeholder={ <div style={ style } className="common-placeholder"></div> }
                       alt={ thumbnailDownloadUrl }></Image>
                <MessageState data={ data } className="message-state__shadow"/>
            </div>
        </Spin>
    </>;
}
