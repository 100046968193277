import cls from "@/pages/Message/feature/Setting/setting.module.scss";
import { Avatar, Button, Flex, Image, Spin, Tooltip, Typography } from "antd";
import closeIcon from "@/assets/svg/close.svg";
import { copyableProps } from "@/utils";
import { useAppDispatch, useAppSelector } from "@/store";
import { setChatSettingVisible, setCurrentPerson, setMessageList } from "@/store/slice/message.ts";
import { EditOutlined } from "@ant-design/icons";
import { addFriend, checkFriendsRelation, updateFriendAlias, ZIMFriendInfo, deleteAllMessage } from "@chat/zim-sdk";
import { useParams } from "react-router-dom";
import VipRect from "@/components/VipRect";

const { Paragraph } = Typography;
const getNumberText = (val: string) => {
    return val.replace(/[^0-9]/g, "");
};
export default function Person() {
    const currentPerson = useAppSelector(state => state.message.currentPerson);
    const currentSettingLoading = useAppSelector(state => state.message.currentSettingLoading);
    const { conversationID } = useParams();
    const dispatch = useAppDispatch();
    const handleClose = () => {
        dispatch(setChatSettingVisible(false));
    };
    const thirdID = getNumberText(currentPerson?.userID || "");
    const updateFriendInfo = (friendInfo: ZIMFriendInfo) => {
        dispatch(setCurrentPerson({
            ...currentPerson,
            ...friendInfo,
        }));
    };
    const deleteMessage = () => {
        deleteAllMessage(conversationID!).then(() => {
            dispatch(setMessageList([]));
        });
    };
    const handleEditNickName = async (val: string) => {
        if (currentPerson?.userID) {
            // 判断是否是好友
            const isFriend = await checkFriendsRelation(currentPerson.userID);
            if (isFriend) {
                // 如果是好友，进行更新备注
                const { friendInfo } = await updateFriendAlias(currentPerson.userID, val);
                updateFriendInfo(friendInfo);
            } else {
                // 如果不是好友，先添加好友，并增加备注
                const { friendInfo } = await addFriend(currentPerson.userID, val);
                updateFriendInfo(friendInfo);
            }
        }
    };
    const basiceInformation = [
        {
            label: "Nick Name:",
            render: () => {
                return <Paragraph
                    // className={ cls.card_item }
                    style={ { margin: 0, maxWidth: 140, color:"#4E5358" } }
                    editable={ {
                        enterIcon: null,
                        icon: <EditOutlined style={ { color: "#4E5358", fontSize: 12 } }/>,
                        onChange: handleEditNickName,
                        tooltip: 'Click to edit Nickname',
                    } }>{ currentPerson?.friendAlias || currentPerson?.userName }</Paragraph>;
            },
        },
        {
            label: "Login platform:",
            value: currentPerson?.loginDevicec === "999" ? "H5" : "APP"
        },
        {
            label: "User Grouping:",
            value: currentPerson?.deptName
        },
        {
            label: "Phone:",
            copyable: true,
            value: currentPerson?.userPhone
        }
    ];
    const registration = [
        {
            label: "Registration Channel:",
            value: currentPerson?.channelId
        },
        {
            label: "Register City:",
            value: currentPerson?.registerCityArea
        },
        {
            label: "Register Platform:",
            value: currentPerson?.platform
        },
    ];
    return <div className={ cls.setting }>
        <div className={ cls.header }>
            <div className={ cls.header__text }>
                User information
            </div>
            <Image wrapperClassName={ cls.header__close } onClick={ handleClose } src={ closeIcon } preview={ false }/>
        </div>

        <div className={ cls.person }>
            <Spin spinning={ currentSettingLoading }>
                <Flex className={ cls.card } gap={8} align="center" vertical style={ { textAlign: "center" } }>
                    <Avatar size={ 80 } src={ currentPerson?.userAvatar }>{ currentPerson?.friendAlias || currentPerson?.userName }</Avatar>
                    <Flex vertical gap={ 4 }>
                        <Flex className={ cls.nickName } align="center" gap={ 4 }>
                            <div>
                                { currentPerson?.friendAlias || currentPerson?.userName }
                            </div>
                            <VipRect level={ currentPerson?.level || 0 } style={{fontSize: 0}}></VipRect>
                        </Flex>
                        <Flex gap={ 4 } className={ cls.normalText }>
                            <div>User Name:</div>
                            <Paragraph className={cls.normalText} style={ {display:"flex", margin: 0, color: "#848FA0" } }
                                       copyable={ copyableProps(currentPerson?.userName!) }>{ currentPerson?.userName }</Paragraph>
                        </Flex>
                        <Flex gap={ 4 } className={ cls.normalText }>
                            <div>User ID:</div>
                            <Paragraph style={ { display: "flex" } }
                                       className={ cls.normalText }
                                       copyable={ copyableProps(thirdID) }>{ thirdID }</Paragraph>
                        </Flex>
                    </Flex>
                </Flex>
                <div className={ cls.gapCell }></div>
                <div className={ cls.card }>
                    <div className={ cls.title }>Basic Information</div>
                    {
                        basiceInformation.map(item => {
                            return <Flex gap={ 4 } justify="space-between" className={ cls.card_item }>
                                <div>{ item.label }</div>
                                {
                                    item.render ? item.render() : item.copyable ?
                                        <Paragraph style={ {display:"flex", alignItems:"center", margin: 0, color: "#4E5358" } }
                                                   copyable={ copyableProps(item.value!) }>{ item.value }</Paragraph> :
                                        <Tooltip title={ item.value }>
                                            <div>{ item.value }</div>
                                        </Tooltip>
                                }
                            </Flex>;
                        })
                    }
                </div>
                <div className={ cls.gapCell }></div>
                <div className={ cls.card }>
                    <div className={ cls.title }>Registration</div>
                    {
                        registration.map(item => {
                            return <Flex gap={ 4 } justify="space-between" className={ cls.card_item }>
                                <div>{ item.label }</div>
                                {/*
                                   <Paragraph className={ cls.value }
                                    copyable={ copyableProps(thirdID) }>{ thirdID }</Paragraph>
                                */ }
                                <Tooltip title={ item.value }>
                                    <div>{ item.value }</div>
                                </Tooltip>
                            </Flex>;
                        })
                    }
                    <Flex align="center" onClick={ deleteMessage } style={ { marginTop: 64 } }>
                        <Button danger block>Clear chat history</Button>
                    </Flex>
                </div>

            </Spin>
        </div>
    </div>;
}
