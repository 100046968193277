import { Avatar, Button, Input, message, Modal, Popover, Space, Switch, Typography } from "antd";
import { logout as SDKLogout } from "@chat/zim-sdk";
import cls from "./layout.module.scss";
import { clearIMProfile, copyableProps, getIMProfile } from "@/utils";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/store";
import { replaceOssImage } from "@chat/shared";
import { setSelfUser, updateSelfUser } from "@/store/slice/common.ts";
import { EditOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { setUserName } from "@/api/app";
import { useRequest } from "ahooks";
import { setCustomer, getCustomer } from "@/api/im";

const { Paragraph } = Typography;


function UserAvatar() {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useAppDispatch();
    const { userInfo } = useAppSelector(state => state.common);
    const [changeNameInput, setChangeNameInput] = useState("");
    const [changeNameVisible, setChangeNameVisible] = useState(false);
    const handlerEditor = () => {
        setChangeNameInput(userInfo?.userName || '');
        setChangeNameVisible(true);
    };
    const { loading, run } = useRequest(setUserName, {
        manual: true,
        onSuccess: (res) => {
            if (res.code === 0) {
                messageApi.success(res.msg);
                setChangeNameVisible(false);
                dispatch(updateSelfUser({
                    userName: changeNameInput
                }));
            } else {
                messageApi.error(res.msg);
            }
        },
        onError: (error) => {
            messageApi.error(JSON.stringify(error));
        }
    });
    const [ispermanent, setIspermanent] = useState(false);
    const { loading: customerLoading, run: customerRun } = useRequest(setCustomer, {
        manual: true,
        onSuccess: (res) => {
            console.log(res);
        }
    });
    const handleUserNameOk = () => {
        run(changeNameInput);
    };
    useEffect(() => {
        getCustomer().then(res => {
            if (res.code === 0) {
                const list = res.data;
                const { imUserID } = getIMProfile();
                const cur = list.find(item => item.imUserId === imUserID);
                if (cur) {
                    setIspermanent(!!parseInt(cur.imPermanent));
                }
            }
        });
    }, []);
    const AvatarContent = () => {
        const logOut = async () => {
            // 可能已经退出登录了
            clearIMProfile();
            try {
                SDKLogout();
            } finally {
                // 清除用户信息
                dispatch(setSelfUser(null));
                navigate("/login", { replace: true });
            }
        };
        return <div className={ cls.content }>
            <div className={ cls.content__item }>
                <div className={ cls.content__item__label }>ID</div>
                <Paragraph copyable={ copyableProps(userInfo?.userID || '') }
                           className={ cls.content__item__value }>{ userInfo?.userID }</Paragraph>
            </div>
            <div className={ cls.content__item }>
                <div className={ cls.content__item__label }>Nickname</div>
                <Space className={ cls.content__item__value }>
                    { userInfo?.userName }
                    <EditOutlined color="#848FA0" onClick={ handlerEditor }
                                  className={ cls.content__item__value__edit }/>
                </Space>
            </div>
            {
                // 只有客服才有常驻功能
                getIMProfile().role.includes("imCustomer") && <div className={ cls.content__item }>
                    <div className={ cls.content__item__label }>Permanent</div>
                    <Space className={ cls.content__item__value }>
                        <Switch size="small"
                                checked={ ispermanent }
                                checkedChildren="open"
                                onChange={ (e) => {
                                    customerRun({ imPermanent: e ? "1" : "0" });
                                    setIspermanent(e);
                                } }
                                unCheckedChildren="close"
                                loading={ customerLoading }/>
                    </Space>
                </div>
            }
            <Button block className={ cls.logout } danger onClick={ logOut }>Log out</Button>
        </div>;
    };


    return <>
        { contextHolder }
        <Popover content={ <AvatarContent/> } trigger="click">
            <Avatar style={ { cursor: "pointer" } } size={ 68 }
                    src={ replaceOssImage(userInfo?.userAvatarUrl) }></Avatar>
        </Popover>
        <Modal width={ 360 } title="change Name" open={ changeNameVisible } okText="Confirm changes"
               onCancel={ () => setChangeNameVisible(false) }
               onOk={ handleUserNameOk } confirmLoading={ loading }>
            <Input value={ changeNameInput } allowClear onChange={ (e) => setChangeNameInput(e.target.value) }></Input>
        </Modal>
    </>;
}

export default UserAvatar;
