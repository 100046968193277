import { GetProps } from "antd";
import Icon from "@ant-design/icons";

function NewChat() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
        <rect x="0.5" y="0.5" width="16" height="16" rx="8" fill="#0085FF"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M7.47864 10.8786C7.47864 11.4309 7.92635 11.8786 8.47864 11.8786C9.03092 11.8786 9.47864 11.4309 9.47864 10.8786V9.47867H10.8786C11.4309 9.47867 11.8786 9.03095 11.8786 8.47867C11.8786 7.92639 11.4309 7.47867 10.8786 7.47867H9.47864V6.07861C9.47864 5.52633 9.03092 5.07861 8.47864 5.07861C7.92635 5.07861 7.47864 5.52633 7.47864 6.07861V7.47867H6.07861C5.52633 7.47867 5.07861 7.92639 5.07861 8.47867C5.07861 9.03095 5.52633 9.47867 6.07861 9.47867H7.47864V10.8786Z"
              fill="white"/>
    </svg>

}

type CustomIconComponentProps = GetProps<typeof Icon>;

export default function ComponentIcon(props: Partial<CustomIconComponentProps>) {
    return <Icon component={ NewChat } { ...props }></Icon>;
}
