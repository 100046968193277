import { useUserInfo } from "@/hooks/useUserInfo";
import { ZIMVideoMessage } from "@chat/zim-sdk";
import { Flex } from "antd";
import VideoIcon from "@/components/icon/Video";
import { formattedTime } from "@chat/shared";

type VideoProps = {
    message: ZIMVideoMessage
};

export default function Video(props: VideoProps) {
    const { senderUserID, videoDuration } = props.message;
    const { userInfo } = useUserInfo(senderUserID);
    return  <Flex gap={ 4 }>
        <span>{ userInfo?.userName }:</span>
        <VideoIcon className="item-text-image"></VideoIcon>
        <span>{ formattedTime(videoDuration * 1000).join(":") }</span>
    </Flex>;
}
