import axios, { AxiosError } from "axios";
import store2 from "store2";
import { getPackage } from "./getPackage";
import { message } from "antd";
import { logout } from "@chat/zim-sdk";

const env = import.meta.env.VITE_APP_FILE;
const urlMap = new Map(
    [
        [1, "https://lt-x-api.lotteryanna.com/app"],
        [2, "https://api.bhaulotterys.com/app"],
        [3, "https://api.supergame123.xyz/app"],
        [5, "https://api.indusbet888.in/app"],
    ]
);

export const enum TOKEN_TYPE {
    ORIGIN = "originToken",
    CHAT = "chatToken"
}

export type TypeResponse<T = any> = {
    code: number;
    msg: string;
    message: string;
    status: number;
    data: T
}
const defaultData: any = {
    lang: 'en_US',
    packageId: 5,
    packageID: 5,
};
export const createHttp = (baseURL: string, token = TOKEN_TYPE.ORIGIN) => {
    const packageId = getPackage() + ""; // 傻比接口 一定要字符串，int报错
    const http = axios.create({
        baseURL,
        timeout: 10000
    });
    http.interceptors.request.use(
        config => {
            defaultData.packageId = packageId;
            defaultData.packageID = packageId;
            config.headers.Token = store2.get(token) || null;
            config.headers.packageId = packageId;
            if (config.headers.get("Content-Type") !== 'multipart/form-data') {
                config.data = {
                    ...defaultData,
                    ...config.data,
                    reqDate: Date.now(),
                };
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    http.interceptors.response.use(
        (res) => {
            if (res.status === 200) {
                switch (res.data.code) {
                    case -1:
                        message.error(res.data.msg || res.data.message);
                        logout();
                        store2.remove("userID");
                        store2.remove("chatToken");
                        store2.remove("imToken");
                        store2.remove("originToken");
                        location.reload();
                        return Promise.reject(res.data);
                    default:
                        return Promise.resolve(res.data);
                }
            }
            return Promise.reject(res);
        },
        (error) => {
            switch (error.code) {
                case AxiosError.ECONNABORTED:
                    message.error("network timeout");
                    break;
                case AxiosError.ERR_NETWORK:
                    message.error("network error");
                    break;
                default:
                    console.log("other error");
            }
            return Promise.reject(error);
        },
    );
    return http;
};

export const getBaseUrlById = () => {
    const baseUrl = urlMap.get(getPackage());
    if (env === "test") {
        return createHttp(import.meta.env.VITE_APP_BASE_URL, TOKEN_TYPE.ORIGIN);
    }
    return createHttp(baseUrl!, TOKEN_TYPE.ORIGIN);
};
