import {
    Button,
    Col,
    Divider,
    Drawer, Flex,
    Image,
    Modal,
    Row,
    Select,
    Space,
} from "antd";
import { useTranslation } from "react-i18next";
import { memo, useState } from "react";
import closeIcon from "@/assets/svg/close.svg";
import cls from "./predictive.module.scss";
import { RandomIcon, SaveIcon } from "@/components/icon";
import { useAppDispatch, useAppSelector } from "@/store";
import { closePredictive } from "@/store/slice/predictive";
import PredictiveTable from "@/pages/Message/feature/Conversation/Predictive/PredictiveTable.tsx";

function PredictiveEvents() {
    const { t } = useTranslation();
    const { visible } = useAppSelector(state => state.predictive);
    const [randomVisible, setRandomVisible] = useState(false);
    const [randomLoading] = useState(false);
    const handleRandom = () => {
    };
    const dispatch = useAppDispatch();
    return <Drawer width={ 680 } className={ cls.predictive }
                   extra={ <Image onClick={ () => dispatch(closePredictive()) } src={ closeIcon }
                                  style={ { cursor: "pointer" } }
                                  preview={ false }/> }
                   closable={ false } title={ t("common.predictiveEvents") }
                   open={ visible } placement="right" mask={ false }>
        <Row>
            <Col>
                <Space split={ <Divider type="vertical" style={ { height: 16, background: "#E5E6EB" } }/> }>
                    <div className={ cls.gameName }>Dice Game</div>
                    <Space className={ cls.gameplay }>
                        <div>Gameplay</div>
                        <Select size="middle" suffixIcon={
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13"
                                 fill="none">
                                <path d="M3 5L6 8L9 5" stroke="#848FA0" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        } style={ { width: 86 } } value={ 1 } options={ [{ value: 1, label: "1min" }] }>
                        </Select>
                    </Space>
                </Space>
            </Col>
            <Col style={ { marginLeft: "auto" } }>
                <Space>
                    <Button icon={ <RandomIcon/> } onClick={ () => setRandomVisible(true) }>Random</Button>
                    <Button type="primary" icon={ <SaveIcon/> }>Save</Button>
                </Space>
            </Col>
        </Row>
        <Modal width={ 360 } title={<div style={{textAlign: "center"}}>Kind Reminder</div>} open={ randomVisible }
               onCancel={ () => setRandomVisible(false) }
               onOk={ handleRandom } confirmLoading={ randomLoading }>
            <Flex vertical align="center" justify="center">
                <div>Once generated, results cannot be undone.</div>
                <div>
                    Confirm random generation?
                </div>
            </Flex>
        </Modal>
        <PredictiveTable/>
    </Drawer>;
}

export default memo(PredictiveEvents);
