import { ChatMessageItem, MessageType } from "@chat/zim-sdk";

type SystemNotificationProps = {
    data: ChatMessageItem
}
// 系统提示
const getText = (data: ChatMessageItem) => {
    if (data.type === MessageType.TimeTip as any) return data.localTime;
    // const detail = JSON.parse(data.notificationElem.detail);
    // const opUser = detail.opUser;
    // switch (data.type) {
    //     case MessageType.GroupCancelMuted:
    //         return `all bans closed`;
    //     case MessageType.GroupMuted:
    //         return `all bans enabled`;
    //     case MessageType.MemberInvited:
    //         const inviteDetails = JSON.parse(data.notificationElem.detail);
    //         // const inviteOpUser = inviteDetails.opUser;
    //         const invitedUserList = inviteDetails.invitedUserList ?? [];
    //         let inviteStr = "";
    //         invitedUserList.find(
    //             (user: any, idx: number) =>
    //                 (inviteStr += `${ getName(user) } `) && idx > 3
    //         );
    //         const invitedUser = `${ inviteStr }${ invitedUserList.length > 3 ? "..." : "" }`;
    //         return `${ invitedUser } join the group chat`;
    //     // const operator = getName(inviteOpUser);
    //     // return `${ operator } invited ${ invitedUser } to join the group chat`;
    //     case MessageType.GroupCreated:
    //         return `${ getName(opUser) } created the group chat`;
    //     case MessageType.GroupOwnerTransferred:
    //         const transferOpUser = detail.newGroupOwner;
    //         return `${ getName(opUser) } transferred the group owner to ${ getName(transferOpUser) }`;
    //     case MessageType.GroupDismissed:
    //         return `Group disbanded by ${ getName(opUser) }`;
    //     case MessageType.GroupMemberCancelMuted:
    //         return `${ getName(opUser) } unbanned ${ getName(detail.mutedUser) }`;
    //     case MessageType.GroupMemberMuted:
    //         return `${ getName(detail.mutedUser) } wa banned by ${ getName(opUser) } `;
    //     case MessageType.GroupNameUpdated:
    //         return `${ getName(opUser) } changed the group name to ${ detail.group.groupName }`;
    //     case MessageType.GroupAnnouncementUpdated:
    //         return `${ getName(opUser) } modified group announcement`;
    //     default:
    //         return data.contentType;
    // }
};

function SystemNotification(props: SystemNotificationProps) {
    const { data } = props;
    return <div className="system-box" data-key={data.messageID}>
        <span className="system-text">
            { getText(data) }
        </span>
    </div>;
}


export default SystemNotification;
