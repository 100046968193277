import { GetProps } from "antd";
import Icon from "@ant-design/icons";

function RandomIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
            <path d="M11.666 10.125L12.8327 11.2917L11.666 12.4583" stroke="#191C1F" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.666 2.5415L12.8327 3.70817L11.666 4.87484" stroke="#191C1F" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.8333 3.7085H10.7917C8.69759 3.7085 7 5.40608 7 7.50016C7 9.59424 8.69759 11.2918 10.7917 11.2918H12.8333" stroke="#191C1F" strokeWidth="1.16667" strokeLinecap="round"/>
            <path d="M1.16602 11.2918H3.20768C5.30176 11.2918 6.99935 9.59424 6.99935 7.50016C6.99935 5.40608 5.30176 3.7085 3.20768 3.7085H1.16602" stroke="#191C1F" strokeWidth="1.16667" strokeLinecap="round"/>
        </svg>
    )
}

type CustomIconComponentProps = GetProps<typeof Icon>;

export default function ComponentIcon(props: Partial<CustomIconComponentProps>) {
    return <Icon component={ RandomIcon } { ...props }></Icon>;
}
